<template>
  <div ref="container">
    <slot :contentRect="rect" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      rect: new DOMRect(),
      observer: new ResizeObserver(this.lodash.debounce(this.eventHandler, 50))
    }
  },
  mounted () {
    this.observer.observe(this.$refs.container)
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
  methods: {
    eventHandler (entries) {
      this.rect = entries[entries.length - 1].contentRect
      this.$emit('resize', this.rect)
      this.$emit('update:content-rect', this.rect)
    }
  },
  props: {
    contentRect: [Object, DOMRect, DOMRectReadOnly]
  }
}
</script>

<style lang="stylus" scoped></style>
